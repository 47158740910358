<template>
    <v-card flat class="pa-3 mt-2">
        <v-card-title>Paciente - {{ this.paciente.paciente }}</v-card-title>
        <v-form class="multi-col-validation">
            <v-card-text class="pt-5">
                <v-row>

                    <v-col cols="12" md="12">

                        <v-tabs v-model="tab" show-arrows>
                            <v-tab v-for="tab in tabs" :key="tab.id">
                                <span>{{ tab.title }}</span>
                            </v-tab>
                        </v-tabs>

                        <v-tabs-items v-model="tab">
                            <v-tab-item>
                                <v-card flat class="pa-3 mt-2">
                                    <v-card-text ref="form">
                                        <v-form class="multi-col-validation mt-6">
                                            <v-row>
                                                <v-col md="4" cols="12">
                                                    <v-text-field v-model="paciente.paciente" label="Paciente *" dense
                                                        outlined :error-messages="errorMessages" ref="paciente">
                                                    </v-text-field>
                                                </v-col>

                                                <v-col md="4" cols="12">
                                                    <v-text-field v-model="paciente.cpf" label="CPF *" v-mask="maskCpf"
                                                        dense outlined :error-messages="errorMessages" ref="cpf">
                                                    </v-text-field>
                                                </v-col>

                                                <v-col md="4" cols="12">
                                                    <v-text-field v-model="paciente.telefone" label="Telefone *" dense
                                                        outlined v-mask="'(##) #####-####'"
                                                        :error-messages="errorMessages" ref="paciente">
                                                    </v-text-field>
                                                </v-col>

                                                <v-col md="4" cols="12">
                                                    <v-text-field v-model="paciente.email" label="E-mail" dense outlined
                                                        :error-messages="errorMessages" ref="email">
                                                    </v-text-field>
                                                </v-col>


                                                <v-col md="4" cols="12">
                                                    <v-text-field v-model="paciente.cep" label="CEP" dense
                                                        v-mask="maskCep" outlined @blur="getCep()"
                                                        :error-messages="errorMessages">
                                                    </v-text-field>
                                                </v-col>

                                                <v-col md="4" cols="12">
                                                    <v-text-field v-model="paciente.estado" label="Estado" dense
                                                        outlined :error-messages="errorMessages">
                                                    </v-text-field>
                                                </v-col>

                                                <v-col md="4" cols="12">
                                                    <v-text-field v-model="paciente.cidade" label="Cidade" dense
                                                        outlined :error-messages="errorMessages">
                                                    </v-text-field>
                                                </v-col>

                                                <v-col md="4" cols="12">
                                                    <v-text-field v-model="paciente.bairro" label="Bairro" dense
                                                        outlined :error-messages="errorMessages">
                                                    </v-text-field>
                                                </v-col>

                                                <v-col md="4" cols="12">
                                                    <v-btn dark text @click="alterarPaciente()" class="primary" block>
                                                        <v-icon>{{ icons.mdiContentSave }}</v-icon>
                                                    </v-btn>
                                                </v-col>


                                            </v-row>
                                        </v-form>
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>

                            <v-tab-item>
                                <v-card flat class="pa-3 mt-2">
                                    <v-card-text ref="form">
                                        <v-row>
                                            <v-col md="6" cols="12">
                                                <v-select v-model="paciente.idAnamnese" :items="anamneses"
                                                    item-value="id" item-text="anamnese" label="Anamnese" outlined
                                                    dense></v-select>
                                            </v-col>
                                            <v-col md="6" cols="12">
                                                <v-btn dark text @click="gerarAnamneseNova()" class="primary" block>
                                                    Gerar nova anamnese
                                                </v-btn>
                                            </v-col>


                                            <v-col md="12" cols="12">
                                                <p class="mt-3" style="font-size: 16px;"><b>Últimas perguntas
                                                        realizadas</b></p>
                                            </v-col>
                                            <v-col md="12" cols="12">
                                                <v-divider></v-divider>
                                            </v-col>

                                            <v-col md="12" cols="12">
                                                <v-card-text v-for="item in anamneseRespostas" :key="item.id">
                                                    <v-row>
                                                        <v-col md="6" cols="12">
                                                            <p><b>Pergunta:</b> {{ item.anamnesePergunta }}</p>
                                                        </v-col>
                                                        <v-col md="6" cols="12">
                                                            <p><b>Resposta:</b> {{ item.resposta }}</p>
                                                        </v-col>
                                                        <v-col md="12" cols="12">
                                                            <v-divider></v-divider>
                                                        </v-col>

                                                    </v-row>
                                                </v-card-text>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>

                                </v-card>
                            </v-tab-item>

                            <v-tab-item>
                                <v-card flat class="pa-3 mt-2">
                                    <v-card-text ref="form">
                                        <v-row>
                                            <v-col md="12" cols="12">
                                                <v-data-table :headers="headersConsulta" :items="consultas"
                                                    :search="search" sort-by="nome" :footer-props="{
                                                        showFirstLastPage: true,
                                                        'items-per-page-text': 'Dados por página'
                                                    }">

                                                    <template v-slot:top>
                                                        <v-dialog v-model="dialogConsulta" persistent max-width="1000px"
                                                            transition="dialog-bottom-transition">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn color="primary" dark v-bind="attrs" v-on="on"
                                                                    style="margin-bottom: 20px;"
                                                                    @click="novaConsultaCad()">
                                                                    Nova consulta
                                                                </v-btn>

                                                                <v-card-title>
                                                                    <v-text-field v-model="search"
                                                                        append-icon="mdi-magnify" label="Pesquisar"
                                                                        single-line hide-details>
                                                                    </v-text-field>
                                                                </v-card-title>
                                                            </template>
                                                            <v-card>
                                                                <v-toolbar>
                                                                    <v-btn icon @click="dialogConsulta = false">
                                                                        <v-icon>{{ icons.mdiClose }}</v-icon>
                                                                    </v-btn>
                                                                    <v-toolbar-title>Nova consulta
                                                                    </v-toolbar-title>
                                                                    <v-spacer></v-spacer>
                                                                    <v-btn dark text @click="cadastrarConsulta()"
                                                                        class="primary">
                                                                        <v-icon>{{ icons.mdiContentSave }}</v-icon>
                                                                    </v-btn>
                                                                </v-toolbar>
                                                                <v-tabs v-model="tabConsulta" show-arrows>
                                                                    <v-tab v-for="tab in tabsConsultas" :key="tab.id">
                                                                        <span>{{ tab.title }}</span>
                                                                    </v-tab>
                                                                </v-tabs>

                                                                <v-tabs-items v-model="tabConsulta">
                                                                    <v-tab-item>
                                                                        <v-card flat class="pa-3 mt-2">
                                                                            <v-card-text>
                                                                                <v-form class="multi-col-validation ">
                                                                                    <v-row>
                                                                                        <v-col md="6" cols="12">
                                                                                            <v-select
                                                                                                v-model="consulta.idDentista"
                                                                                                :items="dentistas"
                                                                                                :disabled="consulta.id != null"
                                                                                                item-value="id"
                                                                                                item-text="dentista"
                                                                                                label="Profissional *"
                                                                                                outlined dense>
                                                                                            </v-select>

                                                                                        </v-col>

                                                                                        <v-col md="6" cols="12">
                                                                                            <v-text-field
                                                                                                v-model="consulta.consulta"
                                                                                                label="Consulta *" dense
                                                                                                outlined ref="consulta">
                                                                                            </v-text-field>
                                                                                        </v-col>

                                                                                        <v-col md="6" cols="12">
                                                                                            <v-menu v-model="menu1"
                                                                                                :close-on-content-click="false"
                                                                                                transition="scale-transition"
                                                                                                offset-y
                                                                                                max-width="290px"
                                                                                                min-width="auto">
                                                                                                <template
                                                                                                    v-slot:activator="{ on, attrs }">
                                                                                                    <v-text-field
                                                                                                        v-model="computedDateFormatted"
                                                                                                        dense outlined
                                                                                                        label="Dia da consulta"
                                                                                                        readonly
                                                                                                        v-bind="attrs"
                                                                                                        v-on="on">
                                                                                                    </v-text-field>
                                                                                                </template>
                                                                                                <v-date-picker
                                                                                                    v-model="date"
                                                                                                    no-title
                                                                                                    @input="menu2 = false">
                                                                                                </v-date-picker>
                                                                                            </v-menu>
                                                                                        </v-col>

                                                                                        <v-col md="6" cols="12">
                                                                                            <v-text-field
                                                                                                v-model.lazy="consulta.valor"
                                                                                                label="Valor *"
                                                                                                v-money="money" dense
                                                                                                :disabled="consultaPagamento.total && consulta.id != null"
                                                                                                outlined ref="valor">
                                                                                            </v-text-field>
                                                                                        </v-col>

                                                                                        <v-col class="d-flex" cols="12"
                                                                                            sm="6">
                                                                                            <v-select
                                                                                                v-model="consulta.confirmacao"
                                                                                                :items="tiposConfimacao"
                                                                                                item-value="id"
                                                                                                item-text="name"
                                                                                                label="Confirmação"
                                                                                                outlined dense>
                                                                                            </v-select>
                                                                                        </v-col>

                                                                                        <v-col md="6" cols="12">
                                                                                            <v-menu ref="menu"
                                                                                                v-model="menu2"
                                                                                                :close-on-content-click="false"
                                                                                                :return-value.sync="consulta.hora"
                                                                                                transition="scale-transition">
                                                                                                <template
                                                                                                    v-slot:activator="{ on, attrs }">
                                                                                                    <v-text-field
                                                                                                        v-model="consulta.hora"
                                                                                                        label="Hora da consulta"
                                                                                                        readonly
                                                                                                        v-bind="attrs"
                                                                                                        v-on="on"
                                                                                                        outlined dense>
                                                                                                    </v-text-field>
                                                                                                </template>
                                                                                                <v-time-picker
                                                                                                    v-if="menu2"
                                                                                                    v-model="consulta.hora"
                                                                                                    format="24hr"
                                                                                                    @click:minute="$refs.menu.save(consulta.hora)">
                                                                                                </v-time-picker>
                                                                                            </v-menu>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                </v-form>

                                                                                <span
                                                                                    v-html="selectedEvent.details"></span>
                                                                            </v-card-text>
                                                                        </v-card>
                                                                    </v-tab-item>

                                                                    <v-tab-item>
                                                                        <v-card flat class="pa-3 mt-2">
                                                                            <v-card-text>
                                                                                <v-form class="multi-col-validation">
                                                                                    <v-row>
                                                                                        <v-col md="4" cols="12">
                                                                                            <v-text-field
                                                                                                v-model.lazy="valorPagar"
                                                                                                label="Valor a ser pago*"
                                                                                                v-money="money1" dense
                                                                                                outlined>
                                                                                            </v-text-field>
                                                                                        </v-col>

                                                                                        <v-col class="d-flex" cols="12"
                                                                                            sm="4">
                                                                                            <v-select
                                                                                                v-model="consultaPagamento.tipo"
                                                                                                :items="tiposPagamento"
                                                                                                item-value="id"
                                                                                                item-text="name"
                                                                                                label="Permissão"
                                                                                                outlined dense>
                                                                                            </v-select>
                                                                                        </v-col>

                                                                                        <v-col md="4" cols="12"
                                                                                            class="text-center">
                                                                                            <v-btn color="primary"
                                                                                                @click="salvarPagamento()"
                                                                                                block
                                                                                                :disabled="consultaPagamento.total">
                                                                                                Salvar pagamento
                                                                                            </v-btn>
                                                                                        </v-col>

                                                                                        <v-col class="d-flex mt-3"
                                                                                            cols="12" md="12">
                                                                                            <p class="text-left">
                                                                                                Valor da consulta:
                                                                                                <b>{{ totalConsulta
                                                                                                }}</b> -
                                                                                                Total pago:
                                                                                                <b>{{ totalPago }}</b>
                                                                                            </p>
                                                                                        </v-col>

                                                                                        <v-col md="12" cols="12">
                                                                                            <v-data-table
                                                                                                :headers="headersPagamento"
                                                                                                :items="consultaPagamentos"
                                                                                                class="elevation-1"
                                                                                                :footer-props="{
                                                                                                    showFirstLastPage: true,
                                                                                                    'items-per-page-text': 'Dados por página'
                                                                                                }">
                                                                                                <template
                                                                                                    v-slot:no-data>
                                                                                                    Nenhum pagamento
                                                                                                    efetuado
                                                                                                </template>
                                                                                            </v-data-table>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                </v-form>

                                                                                <span
                                                                                    v-html="selectedEvent.details"></span>
                                                                            </v-card-text>
                                                                        </v-card>
                                                                    </v-tab-item>
                                                                </v-tabs-items>
                                                            </v-card>
                                                        </v-dialog>
                                                    </template>
                                                    <template v-slot:[`item.actions`]="{ item }">
                                                        <v-icon small class="mr-2" @click="editItem(item)">
                                                            {{ icons.mdiPencil }}
                                                        </v-icon>
                                                        <v-icon small @click="deleteItem(item)">
                                                            {{ icons.mdiDelete }}
                                                        </v-icon>
                                                    </template>
                                                    <template v-slot:no-data>
                                                        Nenhum paciente encontrado
                                                    </template>
                                                </v-data-table>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>

                                </v-card>
                            </v-tab-item>


                            <v-tab-item>
                                <v-card flat class="pa-3 mt-2">
                                    <v-card-text ref="form">
                                        <v-row>
                                            <v-col md="3" cols="12">
                                                <v-file-input v-model="imagem" accept="image/png, image/jpeg, image/bmp"
                                                    placeholder="Imagem" :prepend-icon="icons.mdiFileImage" dense
                                                    label="Imagem">
                                                </v-file-input>
                                            </v-col>
                                            <v-col md="3" cols="12">
                                                <v-btn dense color="primary" @click="salvarImagem()">Salvar imagem
                                                </v-btn>
                                            </v-col>
                                            <v-col md="12" cols="12" v-if="imagens.length > 0">
                                                <v-sheet class="mx-auto" elevation="8">
                                                    <v-slide-group v-model="model" class="pa-4" show-arrows>
                                                        <v-slide-item v-for="n in imagens" :key="n.id"
                                                            v-slot="{ active, toggle }">
                                                            <v-card :color="active ? 'primary' : 'grey lighten-1'"
                                                                class="ma-4" height="auto" width="15%" @click="toggle">

                                                                <v-img :lazy-src="baseUrl + n.imagens"
                                                                    :src="baseUrl + n.imagens"></v-img>

                                                                <v-row class="fill-height" align="center"
                                                                    justify="center">
                                                                    <v-scale-transition>
                                                                        <v-icon v-if="active" color="white" size="48"
                                                                            v-text="'mdi-close-circle-outline'">
                                                                        </v-icon>
                                                                    </v-scale-transition>
                                                                </v-row>
                                                            </v-card>
                                                        </v-slide-item>
                                                    </v-slide-group>

                                                    <v-expand-transition>
                                                        <v-sheet v-if="model != null" height="200" tile>
                                                            <v-row class="fill-height" align="center" justify="center">
                                                                <h3 class="text-h6">
                                                                    <v-btn @click="deletarImagem" color="error">
                                                                        <v-icon>{{ icons.mdiDelete }}</v-icon>
                                                                        Deletar imagem
                                                                    </v-btn>

                                                                </h3>
                                                            </v-row>
                                                        </v-sheet>
                                                    </v-expand-transition>
                                                </v-sheet>
                                            </v-col>
                                            <v-col md="12" cols="12" v-else>
                                                <p>Esse paciente não possui imagens, cadastre novas imagens para esse
                                                    paciente</p>
                                            </v-col>

                                        </v-row>
                                    </v-card-text>

                                </v-card>
                            </v-tab-item>

                            <v-tab-item>
                                <v-card flat class="pa-3 mt-2">
                                    <v-card-text ref="form">
                                        <v-row>
                                            <v-col md="3" cols="12">
                                                <v-btn dense color="primary" @click="dialogAtestado = true">Novo
                                                    atestado
                                                </v-btn>
                                            </v-col>
                                            <v-col md="12" cols="12" v-if="atestados.length > 0">
                                                <v-data-table :headers="headersAtestado" :items="atestados"
                                                    :footer-props="{
                                                        showFirstLastPage: true,
                                                        'items-per-page-text': 'Dados por página'
                                                    }">
                                                    <template v-slot:[`item.actions`]="{ item }">
                                                        <v-icon small class="mr-2" @click="visualizarAtestado(item)">
                                                            {{ icons.mdiPencil }}
                                                        </v-icon>
                                                        <v-icon small @click="deletarAtestado(item)">
                                                            {{ icons.mdiDelete }}
                                                        </v-icon>
                                                        <router-link :to="'/imprimirAtestado/'+item.id" target="_blank" style="margin-left: 10px;">
                                                            <v-icon small>
                                                                {{ icons.mdiPrinter }}
                                                            </v-icon>
                                                        </router-link>

                                                    </template>
                                                    <template v-slot:no-data>
                                                        Nenhum receituário encontrado
                                                    </template>

                                                </v-data-table>
                                            </v-col>
                                            <v-col md="12" cols="12" v-else>
                                                <p>Esse paciente não possui atestados</p>
                                            </v-col>

                                            <v-dialog v-model="dialogAtestado" persistent max-width="1000px">
                                                <v-card class="text-center">
                                                    <v-toolbar>
                                                        <v-btn icon @click="dialogAtestado = false">
                                                            <v-icon>{{ icons.mdiClose }}</v-icon>
                                                        </v-btn>
                                                        <v-toolbar-title>Novo atestado
                                                        </v-toolbar-title>
                                                        <v-spacer></v-spacer>
                                                        <v-btn dark text @click="salvarAtestado()" class="primary">
                                                            <v-icon>{{ icons.mdiContentSave }}</v-icon>
                                                        </v-btn>
                                                    </v-toolbar>
                                                    <v-card-text class="mt-6">
                                                        <v-row>
                                                            <v-col md="6" cols="12">
                                                                <v-select v-model="atestado.tipo"
                                                                    :items="modelosAtestado" item-value="id"
                                                                    item-text="name" label="Modelo" outlined dense>
                                                                </v-select>
                                                            </v-col>
                                                            <v-col md="6" cols="12">
                                                                <v-btn color="primary" dense block
                                                                    @click="carregarModelo">Visualizar modelo
                                                                </v-btn>
                                                            </v-col>
                                                            <v-col md="12" cols="12">
                                                                <vue-editor v-model="atestado.atestado" />
                                                            </v-col>
                                                        </v-row>


                                                    </v-card-text>
                                                </v-card>
                                            </v-dialog>


                                        </v-row>
                                    </v-card-text>

                                </v-card>
                            </v-tab-item>

                            <v-tab-item>
                                <v-card flat class="pa-3 mt-2">
                                    <v-card-text ref="form">
                                        <v-row>
                                            <v-col md="3" cols="12">
                                                <v-btn dense color="primary" @click="dialogReceituario = true">Novo
                                                    receituário
                                                </v-btn>
                                            </v-col>
                                            <v-col md="12" cols="12" v-if="receituarios.length > 0">
                                                <v-data-table :headers="headersReceituario" :items="receituarios"
                                                    :footer-props="{
                                                        showFirstLastPage: true,
                                                        'items-per-page-text': 'Dados por página'
                                                    }">
                                                    <template v-slot:[`item.actions`]="{ item }">
                                                        <v-icon small class="mr-2" @click="visualizarReceituario(item)">
                                                            {{ icons.mdiPencil }}
                                                        </v-icon>
                                                        <v-icon small @click="deletarReceituario(item)">
                                                            {{ icons.mdiDelete }}
                                                        </v-icon>
                                                        <router-link :to="'/imprimirReceituario/'+item.id" target="_blank" style="margin-left: 10px;">
                                                            <v-icon small>
                                                                {{ icons.mdiPrinter }}
                                                            </v-icon>
                                                        </router-link>
                                                    </template>
                                                    <template v-slot:no-data>
                                                        Nenhum receituário encontrado
                                                    </template>

                                                </v-data-table>
                                            </v-col>
                                            <v-col md="12" cols="12" v-else>
                                                <p>Esse paciente não possui receituário</p>
                                            </v-col>

                                            <v-dialog v-model="dialogReceituario" persistent max-width="1000px">
                                                <v-card class="text-center">
                                                    <v-toolbar>
                                                        <v-btn icon @click="dialogReceituario = false">
                                                            <v-icon>{{ icons.mdiClose }}</v-icon>
                                                        </v-btn>
                                                        <v-toolbar-title>Novo Receituário
                                                        </v-toolbar-title>
                                                        <v-spacer></v-spacer>
                                                        <v-btn dark text @click="salvarReceituario()" class="primary">
                                                            <v-icon>{{ icons.mdiContentSave }}</v-icon>
                                                        </v-btn>
                                                    </v-toolbar>
                                                    <v-card-text class="mt-6">
                                                        <v-row>
                                                            <v-col md="6" cols="12">
                                                                <v-select v-model="receituario.tipo"
                                                                    :items="modelosReceituario" item-value="id"
                                                                    item-text="name" label="Modelo" outlined dense>
                                                                </v-select>
                                                            </v-col>
                                                            <v-col md="6" cols="12">
                                                                <v-btn color="primary" dense block
                                                                    @click="carregarModeloReceituario">Visualizar modelo
                                                                </v-btn>
                                                            </v-col>
                                                            <v-col md="12" cols="12">
                                                                <vue-editor v-model="receituario.receituario" />
                                                            </v-col>
                                                        </v-row>


                                                    </v-card-text>
                                                </v-card>
                                            </v-dialog>


                                        </v-row>
                                    </v-card-text>

                                </v-card>
                            </v-tab-item>

                        </v-tabs-items>
                    </v-col>
                </v-row>
            </v-card-text>

        </v-form>

        <v-dialog v-model="dialogDeleteConsulta" max-width="500px">
            <v-card class="text-center">
                <v-card-text class="text-h5 text-center">Deseja deletar a consulta ?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="warning" outlined @click="dialogDeleteConsulta = false">Cancelar
                    </v-btn>
                    <v-btn color="success" outlined @click="deletarConsulta">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogGerarAnamnese" max-width="600px">
            <v-card>
                <v-toolbar>
                    <v-btn icon @click="dialogGerarAnamnese = false">
                        <v-icon>{{ icons.mdiClose }}</v-icon>
                    </v-btn>
                    <v-toolbar-title>Gerar nova anamnese</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn dark text @click="salvarRespostas()" class="primary">
                        <v-icon>{{ icons.mdiContentSave }}</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text v-for="item in anamnesePerguntas" :key="item.id" class="mt-6">
                    <v-row>
                        <v-col cols="12" md="12">
                            <p style="font-size: 16px;"><b>Pergunta: {{ item.anamnesePerguntas }}</b></p>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-radio-group v-model="item.tipo" row outlined dense style="margin-top: -25px;">
                                <v-radio :label="tipos[0].name" :value="tipos[0].id"></v-radio>
                                <v-radio :label="tipos[1].name" :value="tipos[1].id"></v-radio>
                                <v-radio :label="tipos[2].name" :value="tipos[2].id" v-if="item.tipoSelect == 1">
                                </v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogVisualizar" persistent max-width="1000px">
            <v-card class="text-center">
                <v-toolbar>
                    <v-btn icon @click="dialogVisualizar = false">
                        <v-icon>{{ icons.mdiClose }}</v-icon>
                    </v-btn>
                    <v-toolbar-title>Atestado
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text class="mt-6">
                    <v-row>
                        <v-col md="12" cols="12">
                            <vue-editor v-model="modeloVisualizar" :disabled="true" />
                        </v-col>
                    </v-row>


                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogVisualizarReceituario" persistent max-width="1000px">
            <v-card class="text-center">
                <v-toolbar>
                    <v-btn icon @click="dialogVisualizarReceituario = false">
                        <v-icon>{{ icons.mdiClose }}</v-icon>
                    </v-btn>
                    <v-toolbar-title>Receituário
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text class="mt-6">
                    <v-row>
                        <v-col md="12" cols="12">
                            <vue-editor v-model="modeloVisualizarReceituario" :disabled="true" />
                        </v-col>
                    </v-row>


                </v-card-text>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar" :color="color" class="text-center">
            {{ text }}
        </v-snackbar>
    </v-card>
</template>

<script>
import Vue from "vue";
import { ref } from '@vue/composition-api'
import { mdiClose, mdiContentSave, mdiDelete, mdiPencil, mdiEye, mdiFileImage, mdiPrinter } from '@mdi/js'

import { mask } from 'vue-the-mask'

import { VMoney } from 'v-money'

import { VueEditor } from "vue2-editor";

export default {

    components: { VueEditor },
    directives: { mask, money: VMoney, money1: VMoney }
    ,

    setup() {

        return {
            tab: "",
            tabs: [
                { title: 'Dados Gerais', id: 0 },
                { title: 'Anamnese', id: 1 },
                { title: 'Consultas', id: 2 },
                { title: 'Imagens', id: 3 },
                { title: 'Atestado', id: 4 },
                { title: 'Receituário', id: 5 },
            ],
            tabConsulta: "",
            tabsConsultas: [
                { title: 'Dados Consulta', id: 0 },
                { title: 'Pagamentos', id: 1 },
            ],

            tipos: [{ id: 0, name: "Sim" }, { id: 1, name: "Não" }, { id: 2, name: "Não sei" }],
            paciente: { id: null, paciente: "", cpf: "", cep: "", telefone: "", email: "", estado: "", cidade: "", bairro: "", idEmpresa: localStorage.getItem("idEmpresa"), token: localStorage.getItem("token") },
            anamneses: [],
            anamneseRespostas: [],
            anamnesePerguntas: [],
            consultas: [],
            headersConsulta: [
                {
                    text: 'Consulta',
                    align: 'start',
                    value: 'consulta',
                    sortable: false
                },
                { text: 'Dia da consulta', value: 'dataModificada', sortable: false },
                { text: 'Hora da consulta', value: 'hora', sortable: false },
                { text: 'Valor', value: 'valorModificado', sortable: false },
                { text: 'Confirmação', value: 'confirmacaoModificado', sortable: false },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            headersPagamento: [
                {
                    text: 'Valor pago',
                    align: 'start',
                    value: 'valor',
                    sortable: false
                },
                { text: 'Tipo de pagamento', align: 'start', value: 'tipo', sortable: false },
            ],
            search: '',
            dentistas: [],
            consultaPagamentos: [],
            totalPago: 0,
            totalConsulta: 0,
            valorPagar: 0,
            consultaPagamento: { idCconsulta: 0, valor: 0, tipo: 0, token: localStorage.getItem("token"), total: false },
            novaConsulta: true,
            consulta: {
                confirmacao: 1,
                consulta: "",
                consultaPagamentos: {},
                createdAt: "",
                data: "",
                dentistum: { dentista: '' },
                hora: "",
                id: null,
                idDentista: 0,
                idEmpresa: 0,
                idPaciente: 0,
                paciente: { paciente: '' },
                token: "",
                updatedAt: "",
                valor: "",
            },
            tiposConfimacao: [{ id: 1, name: "Não informado" }, { id: 2, name: "Confirmado" }, { id: 3, name: "Não confirmado" }],
            tiposPagamento: [{ id: 0, name: "Dinheiro" }, { id: 1, name: "Cartão de crédito" }, { id: 2, name: "Cartão de débito" }, { id: 3, name: "Pix" }],
            money1: {
                decimal: ',',
                thousands: '.',
                precision: 2,
            },
            money: {
                decimal: ',',
                thousands: '.',
                precision: 2,
                masked: false /* doesn't work with directive */
            },
            menu1: false,
            time: null,
            menu2: false,
            date: "",
            dateFormatted: "",

            selectedEvent: {
                consulta: {
                    confirmacao: 1,
                    consulta: "",
                    consultaPagamentos: {},
                    createdAt: "",
                    data: "",
                    dentistum: { dentista: '' },
                    hora: "",
                    id: 0,
                    idDentista: 0,
                    idEmpresa: 0,
                    idPaciente: 0,
                    paciente: { paciente: '' },
                    token: "",
                    updatedAt: "",
                    valor: "",
                }
            },
            dialogConsulta: false,
            dialogDeleteConsulta: false,


            dialogGerarAnamnese: false,
            dialog: false,
            dialogDelete: false,
            maskCpf: "###.###.###-##",
            maskCep: "#####-###",

            response: null,
            baseUrl: 'https://viacep.com.br/ws/',

            notifications: false,
            sound: true,
            widgets: false,
            icons: {
                mdiClose,
                mdiDelete,
                mdiContentSave,
                mdiPencil,
                mdiEye,
                mdiFileImage,
                mdiPrinter
            },
            formHasErrors: false,
            errorMessages: '',
            snackbar: false,
            text: '',
            color: 'success',
            editedIndex: -1,
            defaultPaciente: { id: null, paciente: "", cpf: "", cep: "", telefone: "", email: "", estado: "", cidade: "", bairro: "", idEmpresa: localStorage.getItem("idEmpresa"), token: localStorage.getItem("token") },


            model: null,
            rules: [
                value => !value || value.size < 2000000 || 'Imagem tem que ser menor que 2 MB!',
            ],
            imagem: null,
            imagens: [],
            baseUrl: "",

            atestados: [],
            atestado: { id: 0, atestado: "", tipo: 0 },
            dialogAtestado: false,
            content: "",
            modelosAtestado: [
                { id: 0, name: "Atestado de aptidão - 1" },
                { id: 1, name: "Atestado de aptidão - 2" },
                { id: 2, name: "Atestado de aptidão - 3" },
                { id: 3, name: "Atestado de aptidão - 4" },
                { id: 4, name: "Atestado de inaptidão - 1" },
                { id: 5, name: "Atestado de inaptidão - 2" },
                { id: 6, name: "Atestado de inaptidão - 3" },

            ],

            modelosAtestadoTexto: [],
            modeloVisualizar: "",
            dialogVisualizar: false,

            headersAtestado: [
                {
                    text: 'Modelo',
                    align: 'start',
                    value: 'modelo',
                    sortable: false
                },
                { text: 'Data', align: 'start', value: 'createdAt', sortable: false },
                { text: 'Ações', value: 'actions', sortable: false },
            ],

            receituarios: [],
            receituario: { id: 0, receituario: "", tipo: 0 },
            dialogReceituario: false,
            content: "",
            modelosReceituario: [
                { id: 0, name: "Receituário - 1" }

            ],

            modelosReceituarioTexto: [],
            modeloVisualizarReceituario: "",
            dialogVisualizarReceituario: false,
            headersReceituario: [
                {
                    text: 'Modelo',
                    align: 'start',
                    value: 'modelo',
                    sortable: false
                },
                { text: 'Data', align: 'start', value: 'createdAt', sortable: false },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
        }
    },

    methods: {

        pegarDados: function () {
            this.$http.get("pacienteId?id=" + this.$route.params.id + "&token=" + localStorage.getItem("token"))
                .then((res) => {
                    if (res.data.status != undefined && res.data.status == 200) {
                        this.paciente = res.data.paciente
                        this.paciente.token = localStorage.getItem("token")
                        if (res.data.paciente.anamneseResposta.length > 0) {
                            this.paciente.idAnamnese = res.data.paciente.anamneseResposta[0].idAnamnese;
                            this.anamneseRespostas = res.data.paciente.anamneseResposta
                        }



                        this.anamneses = res.data.anamneses
                        this.consultas = this.paciente.consulta
                        for (var i = 0; i < this.consultas.length; i++) {
                            this.consultas[i].dataModificada = this.consultas[i].data.split("T")
                            this.consultas[i].dataModificada = this.formatDate(this.consultas[i].dataModificada[0])

                            this.consultas[i].valorModificado = this.consultas[i].valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
                            this.consultas[i].confirmacaoModificado = "Não informado";
                            if (this.consultas[i].confirmacao == 2) {
                                this.consultas[i].confirmacaoModificado = "Confirmado";
                            } else if (this.consultas[i].confirmacao == 3) {
                                this.consultas[i].confirmacaoModificado = "Não Confirmado";
                            }
                        }

                        this.imagens = this.paciente.imagens;
                        this.atestados = this.paciente.atestados;
                        this.modelosAtestadoTexto = [
                            "<p>Atesto para os devidos fins que " + this.paciente.paciente + " foi por mim examinado(a) e avaliado(a), encontrando-se em pleno estado de saúde, apto(a) a exercer atividade física de competição.</p>" +
                            "<p><br></p><p><br></p><p class='ql-align-center'>[Local e data]</p> <p class='ql-align-center'>______________________________________________________</p>" +
                            "<p class='ql-align-center'>Assinatura, carimbo e CRM</p>",

                            "<p>Atesto para os devidos fins que o(a) Sr.(a) " + this.paciente.paciente + " está apto(a) para a prática de esportes na modalidade [informar] e que se encontra em boas condições de saúde, não sofre de nenhuma doença e não é portador(a) de necessidades especiais.</p>" +
                            "<p><br></p><p><br></p><p class='ql-align-center'>[Local e data]</p> <p class='ql-align-center'>______________________________________________________</p>" +
                            "<p class='ql-align-center'>Assinatura, carimbo e CRM</p>",

                            "<p>Atesto para os devidos fins que o(a) Sr.(a) " + this.paciente.paciente + ", [nacionalidade], inscrito no CPF n.º " + this.paciente.cpf + ", foi por mim examinado(a) nesta data, está em perfeitas condições mental e física, para participar de [citar atividades nas quais o paciente está apto para efetuar], que exigem esforço físico moderado.</p>" +
                            "<p><br></p><p><br></p><p class='ql-align-center'>[Local e data]</p> <p class='ql-align-center'>______________________________________________________</p>" +
                            "<p class='ql-align-center'>Assinatura, carimbo e CRM</p>",

                            "<p>Atesto que o(a) Sr.(a) " + this.paciente.paciente + ", inscrito no CPF n.º " + this.paciente.cpf + ", apresenta boa condição de saúde física para realizar o teste de aptidão física citado no edital normativo do Concurso Público para o cargo de (informar) da Prefeitura Municipal de [informar].</p>" +
                            "<p><br></p><p><br></p><p class='ql-align-center'>[Local e data]</p> <p class='ql-align-center'>______________________________________________________</p>" +
                            "<p class='ql-align-center'>Assinatura, carimbo e CRM</p>",


                            "<p>Atesto para os devidos fins, que o Sr. " + this.paciente.paciente + ", inscrito no CPF n.º " + this.paciente.cpf + ", foi atendido no dia [inserir data] às [inserir horário] apresentando quadro de [inserir o problema de saúde] e necessita de [inserir] dias de repouso</p>" +
                            "<p><br></p><p><br></p><p class='ql-align-center'>[Local e data]</p> <p class='ql-align-center'>______________________________________________________</p>" +
                            "<p class='ql-align-center'>Assinatura, carimbo e CRM</p>",

                            "<p>Atesto para os devidos fins, a pedido do interessado, que " + this.paciente.paciente + ", inscrito no CPF n.º " + this.paciente.cpf + " foi submetido à consulta médica nesta data, no horário das [inserir] horas, e apresenta [especificar o problema de saúde, se necessário]. Em decorrência, deverá permanecer afastado de suas atividades laborativas por um período de [informar] dias, a partir desta data.</p>" +
                            "<p><br></p><p><br></p><p class='ql-align-center'>[Local e data]</p> <p class='ql-align-center'>______________________________________________________</p>" +
                            "<p class='ql-align-center'>Assinatura, carimbo e CRM</p>",

                            "<p>Declaro para os devidos fins que o(a) Sr(a). " + this.paciente.paciente + ", [nacionalidade], [estado civil], [profissão], inscrito no CPF n.º " + this.paciente.cpf + ", paciente que está sob meus cuidados, não possui condições adequadas para trabalhar, devendo se afastar de suas atividades profissionais e permanecer em repouso de [inserir a data de início] a [inserir a data de término].</p>" +
                            "<p><br></p><p><br></p><p class='ql-align-center'>[Local e data]</p> <p class='ql-align-center'>______________________________________________________</p>" +
                            "<p class='ql-align-center'>Assinatura, carimbo e CRM</p>",
                        ]

                        for (var i = 0; i < this.atestados.length; i++) {
                            this.atestados[i].modelo = this.modelosAtestado[this.atestados[i].tipo].name
                            this.atestados[i].createdAt = this.dataAtualFormatada(new Date(this.atestados[i].createdAt))
                        }

                        console.log(this.atestados)


                        this.receituarios = this.paciente.receituarios;

                        this.modelosReceituarioTexto = [
                            "",
                        ]

                        for (var i = 0; i < this.receituarios.length; i++) {
                            this.receituarios[i].modelo = this.modelosReceituario[this.receituarios[i].tipo].name
                            this.receituarios[i].createdAt = this.dataAtualFormatada(new Date(this.receituarios[i].createdAt))
                        }


                        this.$forceUpdate();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            this.$http.get("dentistas?idEmpresa=" + localStorage.getItem("idEmpresa") + "&token=" + localStorage.getItem("token"))
                .then((res) => {
                    if (res.data.status != undefined && res.data.status == 200) {
                        this.dentistas = res.data.dados
                        this.$forceUpdate();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        gerarAnamneseNova: function () {
            var idAnamnese = this.paciente.idAnamnese;

            if (idAnamnese == undefined || idAnamnese == null || idAnamnese == 0) {
                this.text = "Favor selecionar um modelo de anamnese"
                this.color = "warning"
                this.snackbar = true
            } else {
                var anamneses = this.anamneses;
                var anamnesePerguntas = [];
                for (var i = 0; i < anamneses.length; i++) {
                    if (anamneses[i].id == idAnamnese) {
                        anamnesePerguntas = anamneses[i].anamnesePerguntas;
                    }
                }

                for (var i = 0; i < anamnesePerguntas.length; i++) {
                    anamnesePerguntas[i].tipoSelect = anamnesePerguntas[i].tipo
                }

                this.anamnesePerguntas = anamnesePerguntas;
                this.dialogGerarAnamnese = true;
                this.$forceUpdate();
            }
        },

        fecharModal: function () {
            this.formHasErrors = false
            this.paciente = { id: null, paciente: "", cpf: "", cep: "", telefone: "", email: "", estado: "", cidade: "", bairro: "", idEmpresa: localStorage.getItem("idEmpresa"), token: localStorage.getItem("token") };
            this.dialog = !this.dialog
        },

        alterarPaciente: function () {

            if (this.paciente.paciente != "" && this.paciente.cpf != "" && this.paciente.telefone != "") {
                if (!this.validarCPF(this.paciente.cpf)) {
                    this.text = "CPF inválido"
                    this.color = "error"
                    this.snackbar = true

                    return
                }

                if (this.paciente.id != null) {
                    this.$http.post("editarPaciente", this.paciente)
                        .then((res) => {
                            if (res.data.status != undefined && res.data.status == 200) {
                                if (this.paciente.id == null) {
                                    this.pacientesMudar.push(res.data.dados)
                                    this.text = "Paciente cadastrado"
                                    this.color = "success"
                                    this.snackbar = true
                                    this.dialog = !this.dialog
                                } else {
                                    this.text = "Paciente atualizado"
                                    this.color = "success"
                                    this.snackbar = true
                                    this.dialog = !this.dialog
                                }

                                this.pegarDados();

                            } else if (res.data.status != undefined && res.data.status == 500) {
                                this.text = res.data.msg
                                this.color = "error"
                                this.snackbar = true
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }

            } else {
                this.text = "Favor preencher todos campos obrigatórios"
                this.color = "warning"
                this.snackbar = true
            }
        },

        salvarRespostas: function () {
            var anamnesePerguntas = this.anamnesePerguntas;
            var anamnesePergunta = []
            var resposta = []
            for (var i = 0; i < anamnesePerguntas.length; i++) {
                anamnesePergunta.push(anamnesePerguntas[i].anamnesePerguntas)

                if (anamnesePerguntas[i].tipo == 0) {
                    resposta.push("Sim")
                } else if (anamnesePerguntas[i].tipo == 1) {
                    resposta.push("Não")
                } else if (anamnesePerguntas[i].tipo == 2) {
                    resposta.push("Não sei")
                }
            }

            this.$http.post("cadastroAnamneseResposta", { idPaciente: this.paciente.id, idAnamnese: anamnesePerguntas[0].idAnamnese, anamnesePergunta: anamnesePergunta, resposta: resposta, token: localStorage.getItem("token") })
                .then((res) => {
                    if (res.data.status != undefined && res.data.status == 200) {

                        this.text = res.data.msg
                        this.color = "success"
                        this.snackbar = true
                        this.dialogGerarAnamnese = false

                        this.pegarDados();

                    } else if (res.data.status != undefined && res.data.status == 500) {
                        this.text = res.data.msg
                        this.color = "error"
                        this.snackbar = true
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

        },

        cadastrarConsulta: function () {
            var consulta = this.consulta;
            consulta.data = this.date;
            if (consulta.id == null) {
                if (consulta.consulta != "" && consulta.data != "" && consulta.valor != "" && consulta.idPaciente != 0 && consulta.idDentista != 0) {
                    consulta.data = this.date
                    consulta.valor = consulta.valor.replaceAll(".", "").replace(",", ".")

                    this.$http.post("cadastroConsulta", consulta)
                        .then((res) => {
                            if (res.data.status != undefined && res.data.status == 200) {
                                this.text = "Consulta cadastrada"
                                this.color = "success"
                                this.snackbar = true
                                this.dialog = !this.dialog
                                this.pegarDados();
                                this.dialogConsulta = false

                            } else if (res.data.status != undefined && res.data.status == 500) {
                                this.text = res.data.msg
                                this.color = "error"
                                this.snackbar = true
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    this.text = "Favor preencher todos campos obrigatórios"
                    this.color = "warning"
                    this.snackbar = true
                }
            } else {
                if (consulta.consulta != "" && consulta.data != "" && consulta.valor != "") {
                    consulta.data = this.date
                    consulta.valor = consulta.valor.replaceAll(".", "").replace(",", ".")

                    this.$http.post("editarConsulta", consulta)
                        .then((res) => {
                            if (res.data.status != undefined && res.data.status == 200) {
                                this.text = "Consulta atualizada"
                                this.color = "success"
                                this.snackbar = true
                                this.dialog = !this.dialog
                                this.pegarDados();
                                this.dialogConsulta = false

                            } else if (res.data.status != undefined && res.data.status == 500) {
                                this.text = res.data.msg
                                this.color = "error"
                                this.snackbar = true
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    this.text = "Favor preencher todos campos obrigatórios"
                    this.color = "warning"
                    this.snackbar = true
                }
            }
        },

        deletarConsulta: function () {
            this.consulta.token = localStorage.getItem("token")
            this.$http.post("deletarConsulta", this.consulta)
                .then((res) => {
                    if (res.data.status != undefined && res.data.status == 200) {
                        this.text = "Consulta deletada"
                        this.color = "success"
                        this.snackbar = true
                        this.pegarDados();
                        this.dialogConsulta = false
                        this.dialogDeleteConsulta = false

                    } else if (res.data.status != undefined && res.data.status == 500) {
                        this.text = res.data.msg
                        this.color = "error"
                        this.snackbar = true
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        salvarPagamento: function () {
            var pagamento = this.consultaPagamento;
            pagamento.valor = this.valorPagar.replaceAll(".", "").replace(",", ".")

            if (pagamento.valor != "") {
                this.$http.post("efetuarPagamentoConsulta", pagamento)
                    .then((res) => {
                        if (res.data.status != undefined && res.data.status == 200) {
                            this.text = "Pagamento registrado"
                            this.color = "success"
                            this.snackbar = true
                            this.dialog = !this.dialog
                            this.pegarDados();
                            this.dialogConsulta = false
                            this.valorPagar = 0
                            this.consultaPagamento = { idConsulta: 0, valor: 0, tipo: 0, token: localStorage.getItem("token"), total: false }
                            this.$forceUpdate();

                        } else if (res.data.status != undefined && res.data.status == 500) {
                            this.text = res.data.msg
                            this.color = "error"
                            this.snackbar = true
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                this.text = "Favor preencher todos campos obrigatórios"
                this.color = "warning"
                this.snackbar = true
            }
        },

        salvarImagem: function () {
            if (this.imagem != null) {
                var dados = { idPaciente: this.paciente.id, imagens: this.imagem, token: localStorage.getItem("token") }

                this.$http.post("cadastroImagem", dados, { headers: { 'Content-Type': 'multipart/form-data' } })
                    .then((res) => {
                        if (res.data.status != undefined && res.data.status == 200) {
                            this.text = res.data.msg
                            this.color = "success"
                            this.snackbar = true
                            this.imagem = null
                            this.pegarDados();

                            this.$forceUpdate();

                        } else if (res.data.status != undefined && res.data.status == 500) {
                            this.text = res.data.msg
                            this.color = "error"
                            this.snackbar = true
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                this.text = "Favor escolher uma imagem primeiro"
                this.color = "warning"
                this.snackbar = true
            }
        },

        deletarImagem: function () {
            var imagem = this.imagens[this.model];
            imagem.token = localStorage.getItem("token")

            this.$http.post("deletarImagem", imagem)
                .then((res) => {
                    if (res.data.status != undefined && res.data.status == 200) {
                        this.text = res.data.msg
                        this.color = "success"
                        this.snackbar = true
                        this.imagem = null
                        this.model = null
                        this.pegarDados();

                        this.$forceUpdate();

                    } else if (res.data.status != undefined && res.data.status == 500) {
                        this.text = res.data.msg
                        this.color = "error"
                        this.snackbar = true
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

        },

        carregarModelo: function () {
            this.atestado.atestado = this.modelosAtestadoTexto[this.atestado.tipo]
            console.log(this.atestado)
            this.$forceUpdate();
        },

        salvarAtestado: function () {
            if (this.atestado.atestado != null) {
                this.atestado.token = localStorage.getItem("token");
                this.atestado.idPaciente = this.paciente.id;

                this.$http.post("cadastroAtestado", this.atestado)
                    .then((res) => {
                        if (res.data.status != undefined && res.data.status == 200) {
                            this.text = res.data.msg
                            this.color = "success"
                            this.snackbar = true
                            this.imagem = null
                            this.pegarDados();
                            this.dialogAtestado = false
                            this.atestado = { id: null, atestado: "", tipo: 0 };
                            this.$forceUpdate();

                        } else if (res.data.status != undefined && res.data.status == 500) {
                            this.text = res.data.msg
                            this.color = "error"
                            this.snackbar = true
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                this.text = "Favor Escolher um modelo"
                this.color = "warning"
                this.snackbar = true
            }
        },

        deletarAtestado: function (item) {
            item.token = localStorage.getItem("token")
            this.$http.post("deletarAtestado", item)
                .then((res) => {
                    if (res.data.status != undefined && res.data.status == 200) {
                        this.text = res.data.msg
                        this.color = "success"
                        this.snackbar = true
                        this.imagem = null
                        this.pegarDados();
                        this.atestado = { id: null, atestado: "", tipo: 0 };
                        this.$forceUpdate();

                    } else if (res.data.status != undefined && res.data.status == 500) {
                        this.text = res.data.msg
                        this.color = "error"
                        this.snackbar = true
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        visualizarAtestado(item) {
            this.modeloVisualizar = item.atestado
            this.dialogVisualizar = true
        },

        carregarModeloReceituario: function () {
            this.receituario.receituario = this.modelosReceituarioTexto[this.receituario.tipo]
            console.log(this.receituario)
            this.$forceUpdate();
        },

        salvarReceituario: function () {
            if (this.receituario.receituario != null) {
                this.receituario.token = localStorage.getItem("token");
                this.receituario.idPaciente = this.paciente.id;

                this.$http.post("cadastroReceituario", this.receituario)
                    .then((res) => {
                        if (res.data.status != undefined && res.data.status == 200) {
                            this.text = res.data.msg
                            this.color = "success"
                            this.snackbar = true
                            this.imagem = null
                            this.pegarDados();
                            this.dialogReceituario = false
                            this.receituario = { id: null, receituario: "", tipo: 0 };
                            this.$forceUpdate();

                        } else if (res.data.status != undefined && res.data.status == 500) {
                            this.text = res.data.msg
                            this.color = "error"
                            this.snackbar = true
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                this.text = "Favor Escolher um modelo"
                this.color = "warning"
                this.snackbar = true
            }
        },

        deletarReceituario: function (item) {
            item.token = localStorage.getItem("token")
            this.$http.post("deletarReceituario", item)
                .then((res) => {
                    if (res.data.status != undefined && res.data.status == 200) {
                        this.text = res.data.msg
                        this.color = "success"
                        this.snackbar = true
                        this.imagem = null
                        this.pegarDados();
                        this.receituario = { id: null, receituario: "", tipo: 0 };
                        this.receituarios.splice(item, 1)
                        this.$forceUpdate();

                        console.log(this.receituarios)

                    } else if (res.data.status != undefined && res.data.status == 500) {
                        this.text = res.data.msg
                        this.color = "error"
                        this.snackbar = true
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        visualizarReceituario(item) {
            this.modeloVisualizarReceituario = item.receituario
            this.dialogVisualizarReceituario = true
        },

        deleteItem(item) {
            this.consulta = Object.assign({}, item)
            this.dialogDeleteConsulta = true

        },

        novaConsultaCad() {
            this.consulta = {
                confirmacao: 1,
                consulta: "",
                consultaPagamentos: {},
                createdAt: "",
                data: '',
                dentistum: { dentista: '' },
                hora: "",
                id: null,
                idDentista: 0,
                idEmpresa: this.paciente.idEmpresa,
                idPaciente: this.paciente.id,
                paciente: { paciente: '' },
                token: "",
                updatedAt: "",
                valor: "",
                token: localStorage.getItem("token"),
                idEmpresa: localStorage.getItem("idEmpresa")
            }
            this.consultaPagamentos = []
            this.valorPagar = 0
            this.totalConsulta = 0
            this.totalPago = 0

            this.dialogConsulta = true
            this.novaConsulta = true
            this.consultaPagamento.total = true
        },

        editItem(item) {
            this.consulta = item

            this.$http.get("consultaId?id=" + item.id + "&token=" + localStorage.getItem("token"))
                .then((res) => {
                    if (res.data.status != undefined && res.data.status == 200) {
                        this.consulta = res.data.consulta
                        this.consulta.token = localStorage.getItem("token")
                        this.consulta.idPaciente = this.paciente.id;

                        var data = this.consulta.data.split(":")[0].split("T")[0].split("-")
                        this.date = data[0] + "-" + data[1] + "-" + data[2]



                        this.consultaPagamento = { idConsulta: 0, valor: 0, tipo: 0, token: localStorage.getItem("token"), total: false }
                        this.consultaPagamento.idConsulta = this.consulta.id
                        this.consultaPagamentos = this.consulta.consultaPagamentos;
                        var novoPagamento = []

                        var total = 0
                        for (var j = 0; j < this.consultaPagamentos.length; j++) {
                            var pagamento = this.consultaPagamentos[j];
                            total += parseFloat(pagamento.valor)

                            if (pagamento.tipo == 0) {
                                pagamento.tipo = "Dinheiro"
                            }
                            else if (pagamento.tipo == 1) {
                                pagamento.tipo = "Cartão de crédito"
                            }
                            else if (pagamento.tipo == 2) {
                                pagamento.tipo = "Cartão de débito"
                            }
                            else if (pagamento.tipo == 3) {
                                pagamento.tipo = "Pix"
                            }



                            pagamento.valor = parseFloat(pagamento.valor).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
                            novoPagamento.push(pagamento)
                        }

                        if (parseFloat(this.consulta.valor) - total <= 0) {
                            this.consultaPagamento.total = true
                        }

                        this.totalPago = total.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
                        this.totalConsulta = parseFloat(this.consulta.valor).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
                        this.consultaPagamentos = novoPagamento
                        this.novaConsulta = false
                        this.$forceUpdate();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });





            this.pegarDados()

            this.dialogConsulta = true
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.paciente = Object.assign({}, this.defaultPaciente)
                this.editedIndex = -1
            })
        },

        closeDelete() {

            this.dialogDelete = false
            this.$nextTick(() => {
                this.paciente = Object.assign({}, this.defaultPaciente)
                this.editedIndex = -1


            })
        },

        getCep() {
            const url = `${this.baseUrl}${this.paciente.cep}/json/`
            this.$http.get(url).then(resp => {
                const data = resp.data
                if (!data.erro) {
                    this.response = data
                    this.paciente.bairro = this.response.bairro
                    this.paciente.cidade = this.response.localidade
                    this.paciente.estado = this.response.uf

                    this.$forceUpdate();
                } else {
                    alert('Cep não encontrado')
                }
            }).catch(error => {
                console.error(error)
            })
        },

        validarCPF(cpf) {
            cpf = cpf.replace(/[^\d]+/g, '');
            if (cpf == '') return false;
            // Elimina CPFs invalidos conhecidos	
            if (cpf.length != 11 ||
                cpf == "00000000000" ||
                cpf == "11111111111" ||
                cpf == "22222222222" ||
                cpf == "33333333333" ||
                cpf == "44444444444" ||
                cpf == "55555555555" ||
                cpf == "66666666666" ||
                cpf == "77777777777" ||
                cpf == "88888888888" ||
                cpf == "99999999999")
                return false;
            // Valida 1o digito	
            var add = 0;
            for (var i = 0; i < 9; i++)
                add += parseInt(cpf.charAt(i)) * (10 - i);
            var rev = 11 - (add % 11);
            if (rev == 10 || rev == 11)
                rev = 0;
            if (rev != parseInt(cpf.charAt(9)))
                return false;
            // Valida 2o digito	
            add = 0;
            for (var i = 0; i < 10; i++)
                add += parseInt(cpf.charAt(i)) * (11 - i);
            rev = 11 - (add % 11);
            if (rev == 10 || rev == 11)
                rev = 0;
            if (rev != parseInt(cpf.charAt(10)))
                return false;
            return true;
        },

        formatDate(date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        parseDate(date) {
            if (!date) return null

            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },

        getDay(date) {

            const WeekDays = ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'];

            return WeekDays[date.weekday];

        },

        dataAtualFormatada: function (datan) {
            let data = datan,
                dia = data.getDate().toString().padStart(2, '0'),
                mes = (data.getMonth() + 1).toString().padStart(2, '0'),
                ano = data.getFullYear();
            return `${dia}/${mes}/${ano}`;
        }

    },

    created() {

    },

    beforeMount() {
        var logado = localStorage.getItem("login")
        if (logado != undefined && logado == "true") {
            this.baseUrl = process.env.VUE_APP_IMAGE_PATH
            this.pegarDados()
        } else {
            this.$router.push('login')
        }
    },

    watch: {
        name() {
            this.errorMessages = ''
        },

        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },

        date(val) {
            this.dateFormatted = this.formatDate(this.date)
        },
    },

    computed: {
        computedDateFormatted() {
            return this.formatDate(this.date)
        },
    },

    mounted() {
        //this.$refs.calendar.checkChange()
    },
}
</script>
